@import "vars";

:root {
  --wheelWidth: 550px;
}

@media (max-width: 800px) {
  :root {
    --wheelWidth: 350px;
  }
}

$imgPath: "~components/main/styles/images/";
//$imgPath: "~components/main/styles/images/autumn/";


$wheelWidth: var(--wheelWidth);
$asideDist: 20px;

html,
body {
  color: white;
  background: #291578 linear-gradient(180deg, #5C103D, #291578) no-repeat;
  //
  //&.autumn {
  //  background: url(#{$imgPath}bg.svg) no-repeat center ;
  //  background-size: cover;
  //}

  font-family: 'Arial';
  font-style: normal;
  overflow-x: hidden;
  position: relative;
  max-width: 100%;
}

.lang-switcher {
  min-width: 60px;
  position: relative;
  padding-top: 23px;
  z-index: 7;

  a {
    display: block;

    &.active {
      position: absolute;
      top: 0;
    }

    &.inactive {
      margin-top: 5px;
      position: relative;
    }

    &.close {
      display: none;
    }
  }
}

.prise {
  width: 150px;
  height: 150px;
  //background: url(#{$imgPath}prise/1.svg) no-repeat;
  background-size: contain !important;
  margin: 0 auto 30px;
}

//-------------------------------------------------------------------------------------

.logo {
  position: absolute;
  left: $asideDist;
  top: 3%;
  width: 100px;
  z-index: 7;
}

.lang-switcher {
  position: absolute;
  right: $asideDist;
  top: 5%;
  text-align: right;

  a {
    display: block;
    text-align: right;
    width: 100%;
  }
}

.fio {
  position: absolute;
  right: calc(#{$asideDist} + 80px);
  top: 5%;
  font-weight: bold;
  z-index: 7;
  text-align: right;
}

.app-name {
  position: absolute;
  right: $asideDist;
  bottom: 5%;
  z-index: 7;
  font-weight: bold;
  text-transform: uppercase;
}

.blur {
  $blurW: 100px;
  position: absolute;
  width: calc(#{$wheelWidth} + #{$blurW});
  height: calc(#{$wheelWidth} + #{$blurW});
  left: -($blurW - 50px);
  top: -55px;
  background: url(#{$imgPath}blur.svg) no-repeat center;
  background-size: calc(#{$wheelWidth} + (#{$blurW} + 40px));
  z-index: 5;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.left {
  display: block;
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
}

.main-container {
  overflow: hidden;
}

button {
  padding: 5px 30px;
  font-size: 20px;
  @media (max-width: 800px) {
    padding: 5px 30px;
    font-size: 16px;
  }
  gap: 10px;
  text-transform: uppercase;
  cursor: pointer;

  background: linear-gradient(180deg, #FF00A4 0%, #9500FF 100%);
  border: 4px solid #FCF3B0;
  box-shadow: 0 0 22px #C501D5;
  border-radius: 143px;

  flex: none;
  order: 1;
  flex-grow: 0;
  color: #FFFFFF;
  opacity: 0.90;

  &:active,
  &:hover {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.8;
    background: linear-gradient(180deg, #6b6b6b 0%, #7a7a7a 100%);
    border: 4px solid #4d4d4d;
    color: #bbbbbb;
  }
}

.autumn{
  button{
    background: linear-gradient(180deg, #ED7507 -3.63%, #DA232E 100%);
    box-shadow: 0px 0px 22px rgba(255, 199, 0, 0.63);
    border-radius: 143px;
  }
}

.wheel {

  width: $wheelWidth;
  height: $wheelWidth;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 100px;

  .info {
    width: 100%;
    position: absolute;
    z-index: 6;
    text-align: center;

    h1 {
      color: white;
      font-weight: bold !important;
      text-align: center;
      line-height: 50px;
      font-size: 40px;
      padding-left: 8px;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      padding-top: 130px;

      @media (max-width: 800px) {
        padding-top: 90px;
        line-height: 40px;
        font-size: 30px;
      }
    }

    p {
      color: #FFFCB0;
      font-size: 26px;
    }

    span {
      color: #FFFFFF;
      font-size: 12px;
    }
  }


  .bg {
    position: absolute;
    width: $wheelWidth - 10;
    height: $wheelWidth  - 10;
    border-radius: 1000px;
    left: 5px;
    top: 5px;
    background: url(#{$imgPath}ellipse.svg) no-repeat center;
    background-size: $wheelWidth;
    z-index: 2;
  }

  .wheel_items {
    position: absolute;
    width: $wheelWidth;
    height: $wheelWidth;
    background: url(#{$imgPath}baraban.svg) no-repeat center;
    background-size: calc(#{$wheelWidth} + 20%);
    rotate: -2deg;
    z-index: 2;
  }

  @keyframes blinker {
    50% {
      //background-color: red;
    }
  }

  .prises {
    position: relative;
    width: $wheelWidth;
    height: $wheelWidth;
    border-radius: 1000px;
    rotate: -2deg;
    z-index: 2;

    .prise-item,
    .prise1 {
      padding: 20px;
      position: absolute;
      width: calc(#{$wheelWidth} - 85%);
      height: calc(#{$wheelWidth} - 85%);
      left: calc(#{$wheelWidth} - 55.99%);
      top: calc(#{$wheelWidth} - 86%);;
      border-radius: 1000px;
      background: rgb(255, 255, 255) url(#{$imgPath}prise/1.svg) no-repeat center;
      background-size: 60% !important;
      box-shadow: 1px 1px 4px rgb(111, 0, 176);
    }
  }

  .wheel_ring {
    position: absolute;
    width: $wheelWidth;
    height: $wheelWidth;
    background: url(#{$imgPath}circle_arrow.svg) no-repeat center;
    background-size: $wheelWidth;
    z-index: 3;
  }

  .wheel_logo {
    position: absolute;
    width: $wheelWidth;
    height: $wheelWidth;
    background: url(#{$imgPath}center_logo.svg) no-repeat center;
    background-size: calc(#{$wheelWidth} / 4);
    z-index: 4;
  }
}

input {
  color: #4d4d4d;
}

.light-item {
  @extend .bounceIn;
  display: block;
  position: absolute;
  width: calc(#{$wheelWidth} - 95.85%);
  height: calc(#{$wheelWidth} - 95.85%);
  background-color: #ef0000;
  box-shadow: 0 0 3px #ff0000;
  //animation: blinker 1.9s linear infinite;
  border-radius: 1000px;
  z-index: 8;
  opacity: 0;
  $defTop: 0%;
  $defLeft: 0%;

  &.active-light {
    opacity: 1;
  }

  &.light-1 {
    top: $defTop + 1.88%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 51.95%));
  }

  &.light-2 {
    top: $defTop + 2.75%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 39.8%));
  }

  &.light-3 {
    top: $defTop + 7.4%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 28.5%));
  }

  &.light-4 {
    top: $defTop + 14.9%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 18.7%));
  }

  &.light-5 {
    top: $defTop + 24.6%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 11.3%));
  }


  &.light-6 {
    top: $defTop + 35.9%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 6.3%));
  }


  &.light-7 {
    top: $defTop + 48.1%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 5.1%));
  }

  &.light-8 {
    top: $defTop + 60.21%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 6.7%));
  }

  &.light-9 {
    top: $defTop + 71.75%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 11.29%));
  }

  &.light-10 {
    top: $defTop + 81.3%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 19%));
  }

  &.light-11 {
    top: $defTop + 89%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 28.7%));
  }

  &.light-12 {
    top: $defTop + 93.5%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 39.9%));
  }

  &.light-13 {
    top: $defTop + 95.1%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 52.1%));
  }

  &.light-14 {
    top: $defTop + 93.5%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 64.4%));
  }

  &.light-15 {
    top: $defTop + 88.8%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 75.8%));
  }

  &.light-16 {
    top: $defTop + 81.3%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 85.49%));
  }

  &.light-17 {
    top: $defTop + 71.6%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 93%));
  }

  &.light-18 {
    top: $defTop + 60.3%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 97.6%));
  }

  &.light-19 {
    top: $defTop + 48.0%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 99.15%));
  }

  &.light-20 {
    top: $defTop + 35.9%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 97.55%));
  }

  &.light-21 {
    top: $defTop + 24.55%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 92.8%));
  }


  &.light-22 {
    top: $defTop + 14.75%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 85.29%));
  }

  &.light-23 {
    top: $defTop + 7.2%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 75.59%));
  }

  &.light-24 {
    top: $defTop + 2.7%;
    left: calc(#{$wheelWidth} - (#{$defLeft} + 64.15%));
  }
}

.op0 {
  opacity: 0;
}

//-------------------------------------------------------------------------------------
.dn {
  display: none;
}

.rotate-neon,
.prise-item,
.wheel-container,
.info {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  70% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}


/////////////////


@keyframes bounceOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.3);
  }
  70% {
    opacity: 0.3;
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

.neonPos {
  position: absolute;
}

.autumn {
  .neonPos {
    display: none;
  }
}

.rotate-neon {
  animation: rotate 20.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.neon8 {
  @extend .neonPos;
  width: 13.72px;
  height: 174.05px;
  left: -290px;
  top: 26px;

  background: #4FFFA0;
  box-shadow: 0 0 20px #4FFFA0;
  filter: blur(6px);
  border-radius: 66px;
  transform: rotate(31.14deg);
}


.neon10 {
  @extend .neonPos;
  width: 18.91px;
  height: 239.95px;
  left: 699.44px;
  top: 539.09px;
  @media (max-width: 800px) {
    left: 399.44px;
    top: 379.09px;
  }


  background: #4F95FF;
  box-shadow: 0 0 20px #4F95FF;
  filter: blur(11px);
  border-radius: 66px;
  transform: rotate(-30.46deg);
}

.neon9 {
  @extend .neonPos;
  width: 18.91px;
  height: 239.95px;
  left: 699.44px;
  top: 279.09px;

  background: #ff7e4f;
  box-shadow: 0 0 20px #4F95FF;
  filter: blur(11px);
  border-radius: 66px;
  transform: rotate(-30.46deg);
}

.neon8 {
  @extend .neonPos;
  width: 18.91px;
  height: 339.95px;
  left: -199.44px;
  top: -79.09px;

  background: #ffdc4f;
  box-shadow: 0 0 20px #4F95FF;
  filter: blur(11px);
  border-radius: 66px;
  transform: rotate(210.46deg);
}

.neon7 {
  @extend .neonPos;
  width: 18.91px;
  height: 239.95px;
  left: 699.44px;
  top: -79.09px;

  background: #4F95FF;
  box-shadow: 0 0 20px #4F95FF;
  filter: blur(11px);
  border-radius: 66px;
  transform: rotate(-30.46deg);
}

.neon6 {
  @extend .neonPos;
  width: 20.41px;
  height: 436.94px;
  left: -517px;
  top: 221.06px;

  background: #ABF5FF;
  box-shadow: 0 0 20px #ABF5FF;
  filter: blur(4px);
  border-radius: 66px;
  transform: rotate(-22.29deg);

}

.neon5 {
  @extend .neonPos;
  width: 11.36px;
  height: 216.53px;
  left: 275.74px;
  top: 592.94px;

  background: #4FFFA0;
  box-shadow: 0 0 20px #4FFFA0;
  filter: blur(4px);
  border-radius: 66px;
  transform: rotate(98.75deg);
}

.neon4 {
  @extend .neonPos;
  width: 13.92px;
  height: 244.99px;
  left: -155.31px;
  top: 444.56px;
  background: #FE4BFE;
  box-shadow: 0 0 20px #F829B2;
  filter: blur(8px);
  border-radius: 66px;
  transform: matrix(-0.49, 0.87, 0.87, 0.49, 0, 0);
}

.neon3 {
  @extend .neonPos;
  width: 16.74px;
  height: 218.08px;
  left: -110.92px;
  top: 161.93px;

  background: #FE4BFE;
  box-shadow: 0 0 20px #F829B2;
  filter: blur(4px);
  border-radius: 66px;
  transform: rotate(-21.29deg);
}

.neon2 {
  @extend .neonPos;
  width: 12.65px;
  height: 331.66px;
  left: 703.51px;
  top: -33px;

  background: #FDFFAB;
  box-shadow: 0 0 20px #FDFFAB;
  filter: blur(4px);
  border-radius: 66px;
  transform: rotate(50.66deg);
}

.neon1 {
  @extend .neonPos;
  width: 14px;
  height: 331.66px;
  left: 991.15px;
  top: 179.87px;

  background: #FDFFAB;
  box-shadow: 0 0 20px #FDFFAB;
  filter: blur(4px);
  border-radius: 66px;
  transform: rotate(-28.16deg);
}

//-------------------------------------------------------------------------------------
@import "wheel";
