$speed: 360;

@for $i from 1 through $speed {
  .r#{$i} {
    animation: rotate #{$i/(36*5)}s linear infinite;
  }
}

@keyframes rotate{
  to{ transform: rotate(#{$speed}deg); }
}


//.rotate1{
//  animation: spin 2.5s linear infinite;
//}
//.rotate2{
//  animation: spin 1.5s linear infinite;
//}

//@-moz-keyframes spin {
//  100% { -moz-transform: rotate(360deg); }
//}
//@-webkit-keyframes spin {
//  100% { -webkit-transform: rotate(360deg); }
//}

